<template>
  <div>
    <v-navigation-drawer
      v-model="drawerOpen"
      color="#373737"
      :permanent="rail"
      :temporary="!rail"
      class="elevation-3"
      :rail="rail"
      rail-width="60"
      expand-on-hover
      @mouseover="setExpanded(true)"
      @mouseleave="setExpanded(false)"
    >
      <div class="d-flex align-center">
        <v-img
          :src="drawerLogo"
          width="40"
          aspect-ratio="16/9"
          height="45"
          class="mt-4"
        />
        <!-- <v-btn
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="rail = !rail"
          ></v-btn> -->
      </div>

      <v-menu>
        <template v-slot:activator="{ props }">
          <div class="d-flex ma-2 mt-4">
            <div>
              <v-btn icon v-bind="props" variant="tonal" color="#b2d250">
                <v-avatar color="#  " variant="tonal" size="large">
                  <h3 class="font-weight-regular">
                    {{ initials(user?.name) }}
                  </h3>
                </v-avatar>
              </v-btn>
            </div>
            <div class="ml-3" v-if="isExpanded">
              <h3>{{ user?.name }}</h3>
              <h4 class="text-caption">
                {{ user?.email }}
              </h4>
            </div>
          </div>
        </template>
      </v-menu>

      <v-list density="compact" nav>
        <!-- Dashboard -->
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          color="#B6D230"
          value="Dashboard"
          to="/dashboard"
        ></v-list-item>
        <!-- Home -->
        <!-- <v-list-item
          prepend-icon="mdi-view-dashboard"
          title="Genx Home"
          color="#B6D230"
          value="genxhome"
          to="/genxhome"
        ></v-list-item> -->
        <!-- users -->
        <v-list-item
          prepend-icon="mdi-account"
          title="Users"
          v-permission="'READ_USER'"
          color="#B6D230"
          value="users"
          to="/users"
        ></v-list-item>
        <!-- onboarding -->
        <!-- <v-list-item
          prepend-icon="mdi-account"
          title="Onboarding"
          v-permission="'READ_ONBOARDING'"
          color="#B6D230"
          value="onboarding"
          to="/onboarding"
        ></v-list-item> -->
        <!-- attendance  -->
        <v-list-group value="attendance" fluid v-permission="'READ_ATTENDANCE'">
          <template v-slot:activator="{ props }">
            <v-list-item
              prepend-icon="mdi-calendar-check"
              title="Attendance"
              color="#B6D230"
              v-bind="props"
              value="attendance"
            ></v-list-item>
          </template>
          <v-list-item
            prepend-icon="mdi-calendar-clock"
            title="Approval Pending"
            v-permission="'APPROVE_ATTENDANCE'"
            color="#B6D230"
            v-bind="props"
            value="attendance"
            to="/attendance-approval-pending"
          ></v-list-item>
        </v-list-group>

        <!-- users -->
        <v-list-item
          prepend-icon="mdi-calendar"
          title="Leaves"
          v-permission="'READ_LEAVES'"
          color="#B6D230"
          value="leaves"
          to="/leaves"
        ></v-list-item>
        <!-- lead  -->
        <v-list-item
          prepend-icon="mdi-account-group"
          title="Leads"
          value="leads"
          v-permission="'READ_LEAD'"
          color="#B6D230"
          to="/leads"
        ></v-list-item>
        <!-- clients -->
        <v-list-item
          prepend-icon="mdi-domain"
          title="Clients"
          value="clients"
          v-permission="'READ_CLIENT'"
          color="#B6D230"
          to="/clients"
        ></v-list-item>

        <!-- job Approvals -->
        <v-list-item
          prepend-icon="mdi-briefcase-outline"
          title="Job Approvals"
          value="jobApproval"
          v-permission="'READ_JOB_APPROVAL'"
          color="#B6D230"
          to="/job-approvals"
        ></v-list-item>

        <!-- Rollback Approvals -->
        <v-list-item
          prepend-icon="mdi-file-undo-outline"
          title="Rollback Requests"
          value="rollback-requests"
          v-permission="'READ_ROLLBACK'"
          color="#B6D230"
          to="/rollback-requests"
        ></v-list-item>

        <!-- teams  -->
        <v-list-item
          prepend-icon="mdi-account-multiple"
          title="Teams"
          value="teams"
          v-permission="'READ_TEAMS'"
          color="#B6D230"
          to="/teams"
        ></v-list-item>

        <!-- teams  -->
        <v-list-item
          prepend-icon="mdi-account-multiple"
          title="Team"
          value="team"
          v-permission="'READ_TEAM'"
          color="#B6D230"
          @click="teamStore.viewTeam(user.teamId)"
        ></v-list-item>

        <!-- portals -->
        <v-list-item
          prepend-icon="mdi-forum"
          title="Portals"
          v-permission="'READ_PORTAL'"
          value="portals"
          color="#B6D230"
          to="/portals"
        ></v-list-item>

        <!-- jobs -->
        <v-list-item
          prepend-icon="mdi-briefcase"
          title="Jobs"
          value="jobs"
          v-permission="'READ_JOB'"
          color="#B6D230"
          to="/jobs"
        ></v-list-item>

        <!-- daily Allocations -->
        <v-list-item
          prepend-icon="mdi-calendar-multiselect"
          title="Daily Allocations"
          value="dailyAllocation"
          v-if="user?.role !== 'OM' || user?.role !== 'HR'"
          v-permission="'READ_DAILY_ALLOCATION'"
          color="#B6D230"
          @click="teamStore.viewDailyAllocations(user.teamId)"
        ></v-list-item>

        <!-- job applications -->
        <v-list-group
          value="job-applications"
          v-if="user.permissions.includes('EDIT_JOB_APPLICATION')"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              color="#B6D230"
              title="Job Applications"
              prepend-icon="mdi-file-document"
            >
            </v-list-item>
          </template>

          <v-list-item
            title="My"
            value="myJobApplications"
            color="#B6D230"
            to="/job-applications"
            ><template #prepend>
              <v-icon size="small">mdi-file-document</v-icon></template
            >
          </v-list-item>
          <v-list-item
            title="Editor"
            value="editorJobApplications"
            color="#B6D230"
            v-permission="'READ_JOB_APPLICATION'"
            to="/editor-job-applications"
            ><template #prepend>
              <v-icon size="small">mdi-file-document</v-icon></template
            >
          </v-list-item>
        </v-list-group>

        <v-list-item
          prepend-icon="mdi-file-document"
          title="Job Applications"
          value="job-applications"
          v-else
          color="#B6D230"
          v-permission="'READ_JOB_APPLICATION'"
          to="/job-applications"
        ></v-list-item>
        <!--  team job applications -->

        <!-- calendar
        <v-list-item
          prepend-icon="mdi-account-multiple"
          title="Calendar"
          color="#B6D230"
          v-permission="'CALENDAR_READ'"
          value="calendar"
          to="/calendar"
        ></v-list-item> -->

        <!-- interviews -->
        <v-list-item
          prepend-icon="mdi-calendar-check"
          title="Interviews"
          v-permission="'READ_INTERVIEW'"
          color="#B6D230"
          value="interviews"
          to="/interviews"
        ></v-list-item>

        <!-- Selected  -->
        <v-list-item
          prepend-icon="mdi-check"
          title="Selections"
          v-permission="'READ_SELECTION'"
          color="#B6D230"
          value="selected"
          to="/selected"
        ></v-list-item>
        <!-- offers -->
        <v-list-item
          prepend-icon="mdi-thumb-up"
          title="Offers"
          v-permission="'READ_OFFER'"
          color="#B6D230"
          value="Offers"
          to="/offers"
        ></v-list-item>

        <!-- joinings -->
        <v-list-item
          prepend-icon="mdi-crown"
          title="Placements"
          v-permission="'READ_PLACEMENT'"
          color="#B6D230"
          value="placements"
          to="/placements"
        ></v-list-item>

        <v-list-item
          prepend-icon="mdi-handshake"
          title="Closures"
          v-permission="'READ_CLOSURE'"
          color="#B6D230"
          value="closures"
          to="/closures"
        ></v-list-item>

        <!-- targets -->
        <v-list-item
          prepend-icon="mdi-bullseye-arrow"
          title="Targets"
          color="#B6D230"
          v-permission="'READ_TARGET'"
          value="Targets"
          to="/targets"
        ></v-list-item>
        <!-- //reports  -->
        <v-list-group value="reports">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              color="#B6D230"
              v-if="
                user.role === 'SUPER_USER'
                  ? true
                  : user.permissions.some((permission) =>
                      reportPermissions.includes(permission)
                    )
              "
              title="Reports"
              prepend-icon="mdi-chart-box-outline"
            >
            </v-list-item>
          </template>

          <v-list-item
            title="Open Jobs"
            value="openJobs"
            color="#B6D230"
            v-permission="'READ_OPEN_JOB_REPORT'"
            to="/open-jobs-report"
            ><template #prepend> <v-icon size="small">mdi-application</v-icon></template>
          </v-list-item>
          <v-list-item
            title="Achievements"
            value="achievements"
            color="#B6D230"
            v-permission="'READ_ACHIEVEMENT_REPORT'"
            to="/achievements"
            ><template #prepend>
              <v-icon size="small">mdi-trophy-outline</v-icon></template
            >
          </v-list-item>
          <v-list-item
            title="Daily Allocations"
            value="dailyAllocations"
            color="#B6D230"
            v-permission="'READ_DAILY_ALLOCATION_REPORT'"
            to="/daily-allocations-report"
          >
            <template #prepend>
              <v-icon size="small">mdi-calendar</v-icon></template
            ></v-list-item
          >
        </v-list-group>
        <!-- candidates -->
        <v-list-item
          prepend-icon="mdi-account-search"
          title="Candidates"
          value="candidates"
          v-permission="'CANDIDATE_READ'"
          color="#B6D230"
          to="/candidates"
        ></v-list-item>

        <!-- inventory -->
        <v-list-group value="inventory" fluid v-permission="'READ_INVENTORY'">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              color="#B6D230"
              title="Inventory"
              prepend-icon="mdi-monitor-cellphone-star"
            >
            </v-list-item>
          </template>

          <v-list-item
            prepend-icon="mdi-laptop"
            title="Laptops"
            value="laptops"
            color="#B6D230"
            to="/laptops"
          ></v-list-item>

          <v-list-item
            prepend-icon="mdi-sim-outline"
            title="Sim Cards"
            value="simCard"
            color="#B6D230"
            to="/simcards"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-cellphone"
            title="Mobiles"
            value="mobiles"
            color="#B6D230"
            to="/mobiles"
          ></v-list-item>
        </v-list-group>

        <!-- official mails -->
        <v-list-item
          prepend-icon="mdi-email-plus-outline"
          title="Official Mails"
          value="officialMails"
          v-permission="'OFFICIALMAILS_READ'"
          color="#B6D230"
          to="/official-mails"
        ></v-list-item>

        <!-- settings -->
        <v-list-item
          prepend-icon="mdi-cog"
          title="Setting"
          v-permission="'READ_SETTING'"
          value="setting"
          color="#B6D230"
          to="/setting"
        ></v-list-item>
        <!-- chat  -->
        <v-list-group value="chat" fluid>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              color="#B6D230"
              title="Chat"
              prepend-icon="mdi-chat-outline"
            >
            </v-list-item>
          </template>

          <v-list-item
            prepend-icon="mdi-whatsapp"
            title="WhatsApp"
            value="whatsApp"
            color="#B6D230"
            ><WhatsAppMessageDialog
          /></v-list-item>

          <v-list-item
            prepend-icon="mdi-microsoft-outlook"
            title="Outlook"
            value="outlook"
            color="#B6D230"
            ><EmailDialog />
          </v-list-item>
        </v-list-group>

        <!-- myAccount -->
        <v-list-item
          prepend-icon="mdi-account-multiple"
          title="My Account"
          color="#B6D230"
          value="myAccount"
          to="/my-account"
        ></v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            color="blue"
            class="mb-2"
            variant="outlined"
            @click="confirmationSyncDialog = true"
          >
            <p v-if="isExpanded">Sync</p>
            <v-icon v-else>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            block
            color="orange"
            variant="outlined"
            @click="confirmationLogOutDialog = true"
          >
            <p v-if="isExpanded">Log Out</p>
            <v-icon v-else> mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <LoaderDialog :model-value="isLoading" />

    <ConfirmationDialoge
      v-model="confirmationSyncDialog"
      @action-yes="syncUser()"
      @update-model="confirmationSyncDialog = false"
      title="Sync"
      text="Are you sure you want to Sync Account ?"
    />
    <ConfirmationDialoge
      v-model="confirmationLogOutDialog"
      @action-yes="authStore.logout()"
      @update-model="confirmationLogOutDialog = false"
      title="Log out"
      text="Are you sure you want to Log Out ?"
    />
    <NoDataFoundDialog
      v-model="teamStore.noTeamFoundDialog"
      @close-dialog="teamStore.noTeamFoundDialog = false"
      title="Team not Found"
    />
  </div>
</template>

<script setup>
import WhatsAppMessageDialog from "@/components/SharedComponent/WhatsAppMessageDialog.vue";
import EmailDialog from "@/components/SharedComponent/EmailDialog.vue";
import NoDataFoundDialog from "@/components/SharedComponent/dialogs/NoDataFoundDialog.vue";
import LoaderDialog from "@/components/SharedComponent/dialogs/LoaderDialog.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { storeToRefs } from "pinia";
import { initials } from "@/composables/Formatters";
import { computed, inject, ref } from "vue";
import { useDrawerStore } from "../../store/DrawerStore";
import logo from "@/assets/Logo-Light-1200x500.png";
import railLogo from "@/assets/x-logo.png";
import { useAuthStore } from "@/store/AuthStore";
import { useTeamStore } from "@/store/Team/TeamStore";
import { useSettingStore } from "@/store/GenxSetting";
import { getDepartments, getDesignations, getRoleName } from "@/composables/LocalStorage";

const $cookies = inject("$cookies");
const user = $cookies.get("user");
const authStore = useAuthStore();
const settingStore = useSettingStore();
const drawerStore = useDrawerStore();
const teamStore = useTeamStore();
const { drawerOpen } = storeToRefs(drawerStore);
const confirmationSyncDialog = ref(false);
const confirmationLogOutDialog = ref(false);
const rail = ref(true);
const isExpanded = ref(false);
const isLoading = ref(false);

const drawerLogo = computed(() => {
  return isExpanded.value ? logo : railLogo;
});

const setExpanded = (value) => {
  isExpanded.value = value;
};
const syncUser = async () => {
  confirmationSyncDialog.value = false;
  isLoading.value = true;
  await authStore.syncUser(user.id);
  await settingStore.getActiveJobApplicationsStatuses();
  await getDepartments();
  await getDesignations();
  await getRoleName();
  localStorage.setItem(
    "jobApplicationsStatus",
    JSON.stringify(settingStore.activeJobApplicationsStatus)
  );
  isLoading.value = false;
};
const reportPermissions = [
  "READ_DAILY_ALLOCATION_REPORT",
  "READ_OPEN_JOB_REPORT",
  "READ_ACHIEVEMENT_REPORT",
];
</script>

<style scoped>
:deep(.v-list-item__prepend) {
  display: ruby-text;
}
:deep(.v-list-group__items .v-list-item) {
  padding-inline-start: 50px !important;
}
</style>
