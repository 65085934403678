import { toast } from "vue3-toastify";
import { getHourMinuteTimeFormat } from "@/composables/DateTime";
import axios from "axios";

export const copyToClipboard = (text) => {
  const input = document.createElement("input");
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
  toast.success("Copied to Clipboard!", {
    autoClose: 3000,
    theme: "dark",
    position: toast.POSITION.TOP_RIGHT,
    transition: toast.TRANSITIONS.BOUNCE,
  });
};

export const getRandomColor = () => {
  const colors = ["#007acc", "#00a65a", "#ff5722"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const initials = (value) => {
  if (!value) return "";
  const nameArray = value.split(" ");
  return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
};

export const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  return firstInitial + lastInitial;
};

export const getStatusColor = (status) => {
  let formattedStatus = status.toLowerCase();
  if (formattedStatus === "pending") return "orange";
  else if (formattedStatus === "approved") return "green";
  else if (formattedStatus === "absent") return "grey";
  else return "red";
};

export const joiningStatusColor = (status) => {
  const formattedStatus = status.toLowerCase();
  if (formattedStatus === "immediate") return "green";
  else if (formattedStatus === "serving") return "orange";
  else return "red";
};

export const getPriorityColor = (priority) => {
  const formattedPriority = priority?.toLowerCase();
  if (formattedPriority === "high") return "text-red";
  else if (formattedPriority === "medium") return "text-orange";
  else return "text-green";
};

export const getJobStatusColor = (status) => {
  let formatStatus = status.toLowerCase();
  if (formatStatus === "open") return "green";
  else if (formatStatus === "closed") return "blue";
  else if (formatStatus === "hold") return "orange";
  else if (formatStatus === "removed") return "red";
};

export const getPunchInOutTime = (date, value) => {
  if (value === null) {
    return "N/A";
  } else {
    return getHourMinuteTimeFormat(date, value);
  }
};

export const getCapitalizeText = (text) => {
  return text?.split(" ")[0].charAt(0) + text?.substring(1).toLowerCase();
};
export const getAvatar = async (url) => {
  const urlValue = url ?? null;
  await axios
    .get(urlValue, {
      headers: {
        Accept: "*/*",
      },
      responseType: "blob",
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/jpg" });

      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl);
      return imageUrl;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const calculateElementHeight = (content) => {
  return content?.clientHeight;
};

export const formatFileName = (fileObj) => {
  let fileName = fileObj.name;
  let formattedName = fileName.replace(/\s+/g, "_");
  let parts = formattedName.split(".");
  // let fileExtension = parts.pop();
  // formattedName = parts.join(".").replace(/\./g, "");
  if (parts.length > 1) {
    let fileExtension = parts.pop();
    formattedName = parts.join(".").replace(/\./g, "");
    formattedName += "." + fileExtension;
  } else {
    formattedName = formattedName.replace(/\./g, "");
  }
  const updatedFile = new File([fileObj], formattedName, {
    type: fileObj.type,
    lastModified: fileObj.lastModified,
  });

  return updatedFile;
};
