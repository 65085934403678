import { createRouter, createWebHistory } from "vue-router";
import defaultLayout from "../layouts/DefaultLayout.vue";
import TeamLayoutVue from "../layouts/PageLayouts/TeamLayout.vue";

const routes = [
  {
    path: "/",
    redirect: () => {
      return { name: "Dashboard" };
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/UnauthorizedView.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: defaultLayout },
    component: () => import("../views/Dashboard/DashboardView.vue"),
  },
  {
    path: "/genxhome",
    name: "Genx Home",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxHome/GenxHomeView.vue"),
  },
  {
    path: "/account_inactive",
    name: "Account Inactive",
    meta: { layout: defaultLayout },
    component: () => import("../views/Common/AccountInactive.vue"),
  },
  {
    // permission: 'USER_READ'
    path: "/users",
    name: "Users",
    meta: { layout: defaultLayout, permission: "READ_USER" },
    component: () => import("../views/User/Users.vue"),
  },
  {
    // permission: 'USER_READ'
    path: "/onboarding",
    name: "Onboarding",
    meta: { layout: defaultLayout, permission: "READ_ONBOARDING" },
    component: () => import("../views/User/Onboarding.vue"),
  },
  {
    // permission: 'USER_READ'
    path: "/attendance",
    name: "Attendance",
    meta: { layout: defaultLayout, permission: "READ_ATTENDANCE" },
    component: () => import("../views/Attendance/AttendanceView.vue"),
  },
  {
    path: "/leaves",
    name: "Leaves",
    meta: { layout: defaultLayout, permission: "READ_LEAVES" },
    component: () =>
      import("../views/ApprovalPending/LeaveApprovalPending.vue"),
  },
  {
    path: "/targets",
    name: "Targets",
    meta: { layout: defaultLayout, permission: "READ_TARGET" },
    component: () => import("../views/Target/Target.vue"),
  },
  // delete after using
  {
    path: "/jobOverview",
    name: "JobOverview",
    component: () => import("../views/JobOverview.vue"),
  },

  {
    path: "/clientOverview",
    name: "clientOverview",
    component: () => import("../views/ClientOverview.vue"),
  },

  //
  {
    path: "/users/:id",
    name: "User Details",
    meta: { permission: "READ_USER" },
    component: () => import("../views/User/User.vue"),
  },
  {
    path: "/my-account",
    name: "My Account",
    component: () => import("../views/MyAccount/MyAccountView.vue"),
  },
  {
    path: "/attendance-approval-pending",
    name: "Attendance Approval Pending",
    meta: { layout: defaultLayout }, //permission: "READ_PENDING_ATTENDANCE"
    component: () =>
      import("../views/Attendance/AttendanceApprovalPending.vue"),
  },
  {
    path: "/job-application-approval-pending",
    name: "Approvals Pending",
    meta: { layout: defaultLayout }, //permission: 'READ_JOB_APPLICATION_APPROVAL'
    component: () =>
      import("../views/ApprovalPending/JobApplicationApprovalPending.vue"),
  },
  {
    path: "/rollback-requests",
    name: "Rollback Requests",
    meta: { layout: defaultLayout }, //permission: 'READ_JOB_APPLICATION_APPROVAL'
    component: () =>
      import("../views/ApprovalPending/RollbackPendingRequest.vue"),
  },
  {
    path: "/portals",
    name: "Portals",
    meta: { layout: defaultLayout, permission: "READ_PORTAL" },
    component: () => import("../views/Portal/PortalsListView.vue"),
  },
  {
    path: "/teams",
    name: "Teams",
    meta: { layout: defaultLayout, permission: "READ_TEAMS" },
    component: () => import("../views/Team/Teams.vue"),
  },
  {
    path: "/team-details/:id",
    name: "Team Details",
    meta: { layout: TeamLayoutVue },
    component: () => import("../views/Team/Team.vue"),
  },
  {
    path: "/leads",
    name: "Leads",
    meta: { layout: defaultLayout, permission: "READ_LEAD" },
    component: () => import("../views/Lead/Leads.vue"),
  },
  {
    path: "/clients",
    name: "Clients",
    meta: { layout: defaultLayout, permission: "READ_CLIENT" },
    component: () => import("../views/Client/Clients.vue"),
  },
  {
    path: "/client/id=:id",
    name: "Client Details",
    meta: { permission: "READ_CLIENT" },
    component: () => import("../views/Client/Client.vue"),
  },
  {
    path: "/agreements",
    name: "Agreements",
    meta: { permission: "READ_AGREEMENTS" },
    component: () => import("../views/Client/AgreementHistory.vue"),
  },
  {
    path: "/job/:id",
    name: "Job Details",
    component: () => import("../views/Client/ClientJob.vue"),
  },
  {
    path: "/setting",
    name: "Setting",
    meta: { layout: defaultLayout, permission: "READ_SETTING" },
    component: () => import("../views/GenxSetting/GenxSettingView.vue"),
  },
  {
    path: "/role-setting",
    name: "Roles",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/RoleSetting.vue"),
  },
  {
    path: "/holidays-setting",
    name: "Holidays",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/HolidaysSetting.vue"),
  },

  {
    path: "/permission-setting",
    name: "Permissions",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/PermissionSetting.vue"),
  },
  {
    path: "/hiring-status-setting",
    name: "Hiring Status",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/HiringStatusSetting.vue"),
  },
  {
    path: "/hiring-stage-setting",
    name: "Hiring Stage",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/HiringStageSetting.vue"),
  },
  {
    path: "/hiring-process-setting",
    name: "Hiring Process",
    meta: { layout: defaultLayout },
    component: () => import("../views/GenxSetting/HiringProcessSetting.vue"),
  },
  {
    path: "/daily-allocations",
    name: "Daily Allocation",
    meta: { permission: "READ_DAILY_ALLOCATION" },
    component: () => import("../views/DailyAllocation/DailyAllocationView.vue"),
  },
  {
    path: "/jobs",
    name: "Jobs",
    meta: { layout: defaultLayout, permission: "READ_JOB" },
    component: () => import("../views/Jobs/JobsView.vue"),
  },
  {
    path: "/candidates",
    name: "Candidates",
    meta: { layout: defaultLayout, permission: "READ_CANDIDATE" },
    component: () => import("../views/Candidate/CandidateListView.vue"),
  },
  {
    path: "/job-applications",
    name: "Job Applications",
    meta: { layout: defaultLayout, permission: "READ_JOB_APPLICATION" },
    component: () =>
      import("../views/JobApplication/JobApplicationListView.vue"),
  },
  {
    path: "/editor-job-applications",
    name: "Editor Job Applications",
    meta: { layout: defaultLayout, permission: "EDIT_JOB_APPLICATION" },
    component: () => import("../views/JobApplication/EditJobApplications.vue"),
  },

  {
    path: "/resume-preview",
    name: "Resume Preview",
    meta: { layout: defaultLayout },
    component: () => import("../views/ResumePreview.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { layout: defaultLayout },
    component: () => import("../views/CalendarView.vue"),
  },
  {
    path: "/interviews",
    name: "Interviews",
    meta: { permission: "READ_INTERVIEW" },
    component: () => import("../views/Interview/InterviewsView.vue"),
  },
  {
    path: "/placements",
    name: "Placements",
    meta: { layout: defaultLayout, permission: "READ_PLACEMENT" },
    component: () => import("../views/Joining/JoiningListView.vue"),
  },
  {
    path: "/support",
    name: "Support",
    meta: { layout: defaultLayout },
    component: () => import("../views/Support/SupportView.vue"),
  },
  {
    path: "/closures",
    name: "Closures",
    meta: { layout: defaultLayout, permission: "READ_CLOSURE" },
    component: () => import("../views/Closure/ClosureListView.vue"),
  },
  {
    path: "/job-approvals",
    name: "Job Approvals",
    meta: { layout: defaultLayout, permission: "READ_JOB_APPROVAL" },
    component: () =>
      import("../views/ApprovalPending/JobApprovalPendingView.vue"),
  },
  {
    path: "/offers",
    name: "Offers",
    meta: { layout: defaultLayout, permission: "READ_OFFER" },
    component: () =>
      import("../views/JobApplication/pages/OfferedJobApplication.vue"),
  },
  {
    path: "/Selected",
    name: "Selected",
    meta: { layout: defaultLayout, permission: "READ_SELECTION" },
    component: () => import("../views/Selected/Selected.vue"),
  },
  {
    path: "/open-jobs-report",
    name: "Open Jobs",
    meta: { layout: defaultLayout, permission: "READ_OPEN_JOB_REPORT" },
    component: () => import("../views/Reports/OpenPositionReport.vue"),
  },
  {
    path: "/achievements",
    name: "Achievements",
    meta: { layout: defaultLayout, permission: "READ_ACHIEVEMENT_REPORT" },
    component: () => import("../views/Reports/AchievementReport.vue"),
  },
  {
    path: "/daily-allocations-report",
    name: "Daily Allocations",
    meta: { layout: defaultLayout, permission: "READ_DAILY_ALLOCATION_REPORT" },
    component: () =>
      import("../views/Reports/DailyAllocationsAndJobApplicationsReport.vue"),
  },
  {
    path: "/laptops",
    name: "Laptops",
    meta: { layout: defaultLayout, permission: "READ_INVENTORY" },
    component: () => import("../views/Inventory/LaptopView.vue"),
  },

  {
    path: "/simcards",
    name: "Sim cards",
    meta: { layout: defaultLayout, permission: "READ_INVENTORY" },
    component: () => import("../views/Inventory/SimCardView.vue"),
  },
  {
    path: "/mobiles",
    name: "Mobiles",
    meta: { layout: defaultLayout, permission: "READ_INVENTORY" },
    component: () => import("../views/Inventory/MobileView.vue"),
  },
  {
    path: "/official-mails",
    name: "Official Mails",
    meta: { layout: defaultLayout, permission: "READ_OFFICIAL_MAILS" },
    component: () => import("../views/OfficialMails/OfficialMailsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
